import React, { useState, useEffect, useLayoutEffect, memo } from 'react'
import { Alert, Backdrop, CircularProgress, Container, IconButton, LinearProgress, Snackbar, Tooltip } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import api, { get_all } from '../../services/api'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import textLabels from './textLabelsTable'
import NotifyProvider from './NotifyProvider'
import { RemoveCircleOutline } from '@mui/icons-material'


const getItem = (storage, key) => {
  const data = localStorage.getItem(storage)
  return data ? Object.keys(JSON.parse(data)).includes(key) ? JSON.parse(data)[key] : null : null

}

export default function({endpoint, columns, options = {}, title = "Your title here",refresh = false, handlerRefresh = ()=>{}, empresa = false}){
    const [data, setData] = useState({data: [], total: 0})
    const [loading, setLoading] = useState(true)
    
    const [searchText, setSearchText] = useState(getItem("datatable." + endpoint, "searchText") || "")





    useEffect(() => {
        const page = getItem("datatable."+ endpoint, "page") || 0
        const rowsPerPage = getItem("datatable."+ endpoint, "rowsPerPage") || 10
        const sortOrder = getItem("datatable."+ endpoint, "sortOrder") || {name: "id", direction: "asc"}
        let timeoutId;
      
        if (searchText ) {
          // Atrasar a chamada à função fetchData por 500 milissegundos (ou ajuste conforme necessário)
          timeoutId = setTimeout(() => {
            fetchData(page, rowsPerPage, sortOrder?.name, sortOrder?.direction, searchText);
          }, 500);
        
        }else {
 
                fetchData(page, rowsPerPage,  sortOrder?.name, sortOrder?.direction, searchText);
    
        }
        
        return () => {
          // Limpar o timeout se a digitação continuar
          clearTimeout(timeoutId);
          
        };

      }, [searchText, empresa]);
    
    const fetchData = async (page = 0, rowsPerPage = false, order_by=undefined, order=undefined, query="") => {
        
        if (rowsPerPage === false){
            rowsPerPage = getItem("datatable."+ endpoint, "rowsPerPage") || 10
        }
        setData({data: [], total: 0})
        setLoading(true)
        if (empresa){            
            api.post(endpoint, {
              limit: rowsPerPage,
              offset: page * rowsPerPage,
              query:query == null ? "" : query,
              order_by,
              order,
              empresa
            }).then(response => {
                setData(response?.data || {data: [], total: 0})
            }).catch(error => {
                console.log(error)
             
            }).finally(() => {
                setLoading(false)
            })
            return
        }
        const response = await get_all(endpoint, rowsPerPage, page * rowsPerPage, order_by, order, query == null ? "" : query)
        
        setData(response?.data || {data: [], total: 0})
        setLoading(false)
        
    }


  useEffect(() => {
    if (refresh) {
      fetchData()
      handlerRefresh()
    }
  }, [refresh])



  return (


    <Datatable
      title={title}
      data={data?.data}
      columns={columns}
      options={({
        ...options,
        textLabels: {
          ...textLabels, body: {
            noMatch: loading ?
              <CircularProgress color="info" />
              : "Nenhum registro encontrado"
          }
        },
        customToolbar: () => {
          return (
            <>
              <options.customToolbar />
              <Tooltip title="Remover todos os filtros">
                <IconButton onClick={() => { localStorage.removeItem("datatable." + endpoint); location.reload() }} >
                  <RemoveCircleOutline />
                </IconButton>
              </Tooltip>
            </>
          )

        },
        count: data?.total,
        serverSide: true,
        viewColumns: false,
        selectableRows: 'none',
        rowsPerPageOptions: [10, 30, 50, 100, 200],
        rowsPerPage: getItem("datatable." + endpoint, "rowsPerPage") || 10,
        storageKey: "datatable." + endpoint,
        print: false,
        download: false,
        filter: false,
        responsive: 'standard',
        onTableChange: (action, tableState) => {
          const { page, rowsPerPage, searchText, sortOrder

          } = tableState
          switch (action) {
            case 'changePage':
              fetchData(page, rowsPerPage, sortOrder.name, sortOrder.direction, searchText)
              break;
            case 'changeRowsPerPage':
              fetchData(page, rowsPerPage, sortOrder.name, sortOrder.direction, searchText)
              break;
            case 'search':
              setSearchText(searchText)
              break;
            case 'sort':
              fetchData(page, rowsPerPage, sortOrder.name, sortOrder.direction, searchText)


              break;
            default:
            //console.log(action, tableState);
          }
        },

      })}
    />

  )

}



export const Datatable = memo(function ({ data, columns, options = {}, title = "Your title here" }) {
  const defaultTheme = createTheme(
    {

      pallete: {

        mode: 'light',
        primary: {
          main: "#e01b29",
        },

        background: {
          paper: '#EDF1FC',
        },
        text: {
          primary: "#2B2E35",

        },
      }
      ,
      typography: {
        fontFamily: [
          'sans-serif',
          'Roboto',
          'Montserrat',
        ]
      },


    }
  )

  return (

    <ThemeProvider theme={defaultTheme}>

      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={options}
      />
    </ThemeProvider>

  )

})



import React, { useEffect, useState } from 'react';
import Datatable from '../../../components/Datatable';
import FormDialog from '../../../components/Datatable/FormDialog';
import { Autocomplete, Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import api, { create, get_one, update, delete_one } from '../../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import { useNotify } from '../../../components/Datatable/NotifyProvider';
import { useFormError } from '../../../components/Datatable/FormErrorProvider';
import { Close, Done, RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../../components/AuthAdminProvider';
import moment from 'moment';
import CustomTextField from '../../../components/CustomTextField';
import HermesTypeSelect from '../../../components/HermesTypeSelect';
import TunnelSelect from '../../../components/TunnelSelect';
import EmpresaSelect from '../../../components/EmpresaSelect';

const getItem = (storage, key) => {
    const data = localStorage.getItem(storage)
    return data ? Object.keys(JSON.parse(data)).includes(key) ? JSON.parse(data)[key] : null : null

}

export default function Config() {
    const notify = useNotify()
    const auth = useAuth()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [formAccess, setFormAccess] = useState({
        open: false,
        id: null,
    })
    const [empresa, setEmpresa] = useState(getItem('hermes', 'empresa') || null)

    const error = useFormError()
    const [refresh, setRefresh] = useState(false)
    const [active, setActive] = useState(true)
    React.useEffect(() => {
        document.title = "IPV7 Hermes - Configuração"

        error.setErrors([])

    }, [])
    return (
        <>
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
                handlerSubmit={() => {
                    setFormState({ open: false, id: null })
                    setRefresh(true)
                }}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
                ></FormItem>
            <Datatable
              
                refresh={refresh}
                handlerRefresh={() => setRefresh(!refresh)}
                title='Configuração'
                endpoint={`/admin/hermes/config`}
                options={{
                    customToolbar: () =>
                        <>
                            <Tooltip title="Remover todos os filtros">
                                <IconButton onClick={() => { localStorage.removeItem('alarms'); location.reload() }} >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </Tooltip>
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: "ID",
                        options: {
                            filter: false,
                            sort: false,
                            display: false
                        }
                    },
                    {
                        name: 'empresa_name',
                        label: 'Empresa',
                    },
                    {
                        name: 'time_backup',
                        label: "Horário Backup",
                        options: {
                            filter: true,
                        }
                    },
                    {
                        name: 'min_size_backup',
                        label: "Tam. Min. Backup",
                        options: {
                            filter: true,
                        }
                    },
                    {
                        name: "timeout",
                        label: 'Timeout',
                        options: {
                            filter: true,
                            sort: false,
                        }
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    <>
                                        <Tooltip title="Editar">
                                            <IconButton disabled={!auth.hasPerm('config.update')}
                                                onClick={() => setFormState({ open: true, id: tableMeta.rowData[0] })}
                                            >
                                                <EditIcon />

                                            </IconButton>
                                        </Tooltip>
                                       

                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar' }) {
    const notify = useNotify()
    const error = useFormError()
    const auth = useAuth()
    const [isLoaded, setIsLoaded] = React.useState(true)
    const modelState = {
        host_name: '',
        type_vendor: null,
        ip_address: '',
        username: '',
        password: '',
        port: '',
        status: 1,


    }
    const [state, setState] = useState(modelState)

    useEffect(() => {
        setState(modelState)
        if (!open) return
        error.setErrors([])
        if (id) {
            setIsLoaded(false)
            get_one('/admin/hermes/config', id).then(res => {
                setState(res.data.data)
                setIsLoaded(true)
            }).catch(e => {
                console.log(e)
                setIsLoaded(true)
            })
        }
    }, [id, open])



    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const wrapperSubmit = () => {

        if (id) {
            update('/admin/hermes/config', id, state).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {
                    if (res.data.error) {
                        error.setErrors([
                            { field: 'global', msg: res.data.error }
                        ])
                        notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                        return
                    }
                    if (res.data.errors) {
                        console.log(res.data.errors)
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Host atualizado com sucesso', severity: 'success' })
                    error.setErrors([])
                }).catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

            <FormDialog
                isLoaded={isLoaded}
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{id ? 'Editar' : 'Adicionar'} Config</Typography>
                        
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }} pt={1} gap={1}>
                    <EmpresaSelect
                        disabled={true}
                        value={state?.empresa}
                        size="small"
                    />

                    <CustomTextField
                        error={error.verify('min_size_backup')}
                        helperText={error.msgError('min_size_backup')}
                        label='Tamanho Mínimo Backup'
                        value={state?.min_size_backup}
                        name={'min_size_backup'}
                        onChange={handleChange}
                    />
                     <CustomTextField
                        error={error.verify('timeout')}
                        helperText={error.msgError('timeout')}
                        label='Tamanho Mínimo Backup'
                        value={state?.timeout}
                        name={'timeout'}
                        onChange={handleChange}
                    />
                     <CustomTextField
                        error={error.verify('time_backup')}
                        helperText={error.msgError('time_backup')}
                        label='Tamanho Mínimo Backup'
                        value={state?.time_backup}
                        name={'time_backup'}
                        type="time"
                        step="3600"
                        onChange={handleChange}
                    />




                </Box>

            </FormDialog>
        </Box>
    )
}

import { Container, Grid, ListItemButton, ListItemIcon, ListItemText, Paper, ThemeProvider, Typography } from '@mui/material'
import React from 'react'
import { MiscellaneousServices } from '@mui/icons-material'
import FormDialog from './FormDialog'
import { grey } from '@mui/material/colors'
import { IconButton, Tooltip } from '@mui/material';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { useAuth } from '../../../components/AuthProvider';
import { useAuth  as useAuthAdmin} from '../../../components/AuthAdminProvider';

export default function ({admin=false, empresa}) {
    const [open, setOpen] = React.useState(false);
    const auth = admin ? useAuthAdmin() : useAuth();
    return(

     <>

        <FormDialog open={open} handlerClose={()=>setOpen(false)} admin={admin} empresa={empresa}/>
        <Tooltip title="Executar importação de hosts">
            <IconButton disabled={!auth.hasPerm('host.create') || (admin  && !empresa)}
                onClick={() => {setOpen(true)
                }}
            >
                <PlaylistAddIcon />
            </IconButton>
        </Tooltip>
     </>


    )
}